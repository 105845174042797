import React, {useEffect} from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import ServiceFive from '../elements/service/ServiceFive';
import ServiceOne from '../elements/service/ServiceOne';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import HeaderTopBar from '../common/header/HeaderTopBar';
import ContactOne from '../elements/contact/ContactOne';
import ReactGA from 'react-ga';
var BlogListData = BlogClassicData.slice(0, 3);




const LordIntelligence = () => {

    // Google Analytics.
    useEffect(() => {
        ReactGA.pageview(window.location.href.split("#")[1])
    }, [])

    return (
        <>
            <SEO title="LORD Intelligence, Inc." />
            <main className="page-wrapper">
                
            <HeaderTopBar />
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />           

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-center">
                                    <span className="subtitle">OUTSOURCED ACCOUNTING FOR SMALL BUSINESSES</span>
                                    <h1 className="title theme-gradient display-two">We provide<br /> {" "}
                                        <Typed
                                            strings={[
                                                "Bookkeeping",
                                                "Tax Filing",
                                                "Payroll",                                                
                                                "Advisory"
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                        <br/>
                                        Services
                                    </h1>
                                    <p className="description">You focus on your business, we’ll handle your accounting</p>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/bg-image-16.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start About Area  */}
                {/* <AboutOne />                             */}
                {/* End About Area  */}

                <Separator />  

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">We are here to assist small businesses with their financial accounting and tax filing needs</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">LORD Intelligence, Inc. specializes in providing outsourced accounting and financial reporting needs for small business. 
                                                      This includes bookkeeping, tax filing, payroll, advisory services. 
                                                      All of our team members have the expertise and enough knowledge to help you grow. 
                                                      Our clients can expect from us: 
                                    <ul>
                                        <li>A professional accounting . . . </li>
                                    </ul>
                                </p>
                                <div className="readmore-btn">
                                    <Link className="btn-read-more" to={`/about-us-details`}><span>View More</span></Link>
                                </div> <br/> <br/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator /> 

                 {/* Start Team Area  */}
                 <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Our Team"
                            />

{/* 
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Experts."
                                        title = "Our Company Experts."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
 */}
                            </div>
                        </div>
                        <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                    </div>
                </div>
                {/* End Team Area  */}

                <Separator /> 

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                                          
                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-left"
                        />
                    </div>
                </div> */}
                {/* End Service Area  */}


                {/* Start Call To Action Area  */}
                {/* <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div> */}
                {/* End Call To Action Area  */}


                <Separator />   
                {/* Start Blog Area  */}
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Blog"
                                    title = "Our Latest News."
                                    description = "You focus on your business, we’ll handle your accounting"
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Blog Area  */}

                <Separator />
                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Contact Form"
                                    title = ""
                                    description = ""
                                />
                            </div>
                        </div>
                        <ContactOne />
                    </div>
                </div>
                {/* End Contact Area  */}


                {/* Start Mission Area   */}
                {/* <Mission />                     */}
                {/* Start Mission Area  */}


                <Separator />                               
                {/* Start Testimonial Area  */}
                {/* <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Awesome Client."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div> */}
                {/* End Testimonial Area  */}


                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default LordIntelligence;
