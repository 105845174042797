import React, {useEffect} from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import LordAboutDetailsContent from '../components/about/LordAboutDetailsContent';
import ReactGA from 'react-ga';

const LordAboutDetails = ({match: {params: {id}}}) => {

    // Google Analytics.
    useEffect(() => {
        ReactGA.pageview(window.location.href.split("#")[1])
    }, [])

    return (
        <>
            <SEO title="Abut Us Details || LORD Intelligence, Inc." />
            <Layout>
                <div className="rn-blog-details-area">
                    <LordAboutDetailsContent />
                </div>
            </Layout>
        </>
    )
}
export default LordAboutDetails;