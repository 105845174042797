import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: 1,
        title: 'Bookkeeping',
        description: 'Bookkeeping service is the practice of recording and keeping track of financial transactions of your business.. . .'
    },
    {
        icon: 2,
        title: 'Tax Filing',
        description: 'We will prepare your business tax returns with accuracy guaranteed. No matter how complex your situation is . . .'
    },
    {
        icon: 3,
        title: 'Payroll',
        description: 'Payroll service assists with all aspects of payroll on behalf of your business. This arrangement is especially . . .'
    },
    {
        icon: 4,
        title: 'Advisory',
        description: 'Advisory service provides clients with expertise and insight to navigate today’s challenging and complex . . .'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to={`/service-details/${i + 1}`} dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p><br/>
                                <Link to={`/service-details/${i + 1}`} className="btn-default btn-small btn-border">Read More</Link>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;