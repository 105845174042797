import React, {useEffect} from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import TemplateServiceOne from "../elements/service/TemplateServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TeamTwo from "../elements/team/TeamTwo";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import ReactGA from 'react-ga';

const AboutUs = () => {

    // Google Analytics.
    useEffect(() => {
        ReactGA.pageview(window.location.href.split("#")[1])
    }, [])

    return (
        <>
            <SEO title="About Us || LORD Intelligence, Inc." />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">About Our Company</span></h3>
                                    </div>
                                    <h1 className="title display-one">We are here to assist small businesses with their financial accounting and tax filing needs</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start About US Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">We are here to assist small businesses with their financial accounting and tax filing needs</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">LORD Intelligence, Inc. specializes in providing outsourced accounting and financial reporting needs for small business. 
                                                      This includes bookkeeping, tax filing, payroll, advisory services. 
                                                      All of our team members have the expertise and enough knowledge to help you grow. 
                                                      Our clients can expect from us: 
                                    <ul>
                                        <li>A professional accounting . . . </li>
                                    </ul>
                                </p>
                                <div className="readmore-btn">
                                    <Link className="btn-read-more" to={`/about-us-details`}><span>View More</span></Link>
                                </div> <br/> <br/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About US Area  */}


                <Separator /> 

                {/* Start Team Area  */}
                <div className="rwt-team-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                        <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            subtitle = "Our Team"
                        />

                {/* 
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Experts."
                                    title = "Our Company Experts."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                */}
                        </div>
                    </div>
                    <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                </div>
                </div>
                {/* End Team Area  */}


                {/* Start Brand Area  */}
                {/* Removed  */}
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                {/* Removed  */}
                {/* End Service Area  */}

                {/* Start TimelineTwo Area  */}
                {/* Removed  component <TimelineTwo/> */}
                {/* End TimelineTwo Area  */}

                <Separator />

                {/* Start AboutFour Area  */}
                {/* Removed  component <AboutFour/> */}
                {/* End AboutFour Area  */}
                
                {/* Start Elements Area  */}
                {/* Removed  component <CounterUpFour/> */}
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                {/* Removed  components <SectionTitle/> and <TeamFour/> */}
                {/* End Elements Area  */}



                <FooterFour />
            </main>
        </>
    )
}

export default AboutUs;
