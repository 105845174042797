import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";
import {Link} from "react-router-dom";

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            {/*will be used later, as needed.*/}
                            {/* <p><a href="#link">Get the most advanced template <FiChevronRight /></a></p> */}
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span>Delaware, USA</span></p>
                                <p><FiPhone /><span><a href="#">+1 213 401 2773</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    
{/* 
                                    <li><Link to="facebook.com"><FiFacebook /></Link></li>
                                    <li><Link to="twitter.com"><FiTwitter /></Link></li>
                                    <li><Link to="instagram.com"><FiInstagram /></Link></li>
*/}

                                    <li><a target="_blank" href="https://www.linkedin.com/company/lord-intelligence-inc"><FiLinkedin /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
