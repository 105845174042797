import React, {useEffect} from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import ReactGA from 'react-ga';

const Contact = () => {

    // Google Analytics.
    useEffect(() => {
        ReactGA.pageview(window.location.href.split("#")[1])
    }, [])

    return (
        <>
            <SEO title="Contact || LORD Intelligence, Inc." />
            <Layout>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact Form"
                                        title = "Our Contact Address Here"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;