import React from 'react';
import SidebarTag from '../blog/sidebar/SidebarTag';
import SideCategories from '../blog/sidebar/SideCategories';
import Comment from '../blog/Comment';
import {slugify} from "../../utils";
import {Link} from "react-router-dom";
import { FiUser, FiCalendar } from "react-icons/fi";



const LordServiceDetailsContent = ({ data }) => {
    return (
        <>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content">
                                <Link className="btn-read-more" to={`/service`}><span> &#10096;&nbsp; Back</span></Link><br /><br />
                                {data.body.map((value, i) => {
                                    return(
                                        <div key={i} dangerouslySetInnerHTML={{__html: value}} />
                                    )
                                })}
                                <Link className="btn-read-more" to={`/service`}><span> &#10096;&nbsp; Back</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </>
    )
}
export default LordServiceDetailsContent;
