import React, {useEffect} from 'react';


import {Link} from "react-router-dom";
import ReactGA from 'react-ga';

const LordAboutDetailsContent = () => {
    return (
        <>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                        <Link className="btn-read-more" to={`/about-us`}><span> &#10096;&nbsp; Back</span></Link><br /><br />
                            <div className="content">
                            <h3>We are here to assist small businesses with their financial accounting and tax filing needs</h3>   
                            <p className="mb--10">LORD Intelligence, Inc. specializes in providing outsourced accounting and financial reporting needs for small business. 
                                                      This includes bookkeeping, tax filing, payroll, advisory services. 
                                                      All of our team members have the expertise and enough knowledge to help you grow. 
                                                      Our clients can expect from us: 
                                <ul>
                                    <li>A professional accounting department to provide strong financials establish systems and processes to increase profitability, build a plan for growth.</li>
                                    <li>A scalable finance team to assist with a business exit strategy, budgeting and planning.</li>
                                    <li>Strategic guidance with business strategies, financing, raising capital.</li>
                                </ul>
                                <h4>Our Core Values</h4>
                                <ul>
                                    <li>Accountability</li>
                                    <li>Integrity</li>
                                    <li>Teamwork</li>
                                    <li>Quality</li>
                                </ul>
                            </p>
                            </div>
                            <Link className="btn-read-more" to={`/about-us`}><span> &#10096;&nbsp; Back</span></Link>
                        </div>
                    </div>
                </div>
            </div>   
        </>
    )
}
export default LordAboutDetailsContent;
