import React, {useEffect} from 'react';
import ServiceClassicData from '../data/service/ServiceList.json';
import LordServiceDetailsContent from '../components/service/LordServiceDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import ReactGA from 'react-ga';

const LordServiceDetails = ({match: {params: {id}}}) => {
    const serviceId = parseInt(id, 10)
    const data = ServiceClassicData.filter(service => service.id === serviceId);

    // Google Analytics.
    useEffect(() => {
        ReactGA.pageview(window.location.href.split("#")[1])
    }, [])

    const titleSEO = "Service " + id + " Details || LORD Intelligence, Inc.";

    return (
        <>
            <SEO title={titleSEO} />
            <Layout>
                <div className="rn-blog-details-area">
                    <LordServiceDetailsContent data={data[0]}  />
                </div>
            </Layout>
        </>
    )
}
export default LordServiceDetails;