import React from 'react';
import { FiFacebook, FiLinkedin } from "react-icons/fi";
import { SiMedium } from "react-icons/si";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'arno-hovakimyan',
        name: 'Arno Hovakimyan',
        designation: 'Founder',
        location: 'Delaware, USA', 
        description: "Ph.D in Economics",
        description1: "IRS Qualified Tax Preparer",
        description2: 'Arno Hovakimyan founded',
        description3: 'LORD Intelligence, Inc. in 2021 to provide accounting services to small business.',
        socialNetwork: [
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/arno-hovakimyan-b36b2a233/'
            },
            {
                icon: <SiMedium />,
                url: 'https://medium.com/@arno_50445/'
            },
            // {
            //     icon: <FiFacebook />,
            //     url: 'https://www.facebook.com/profile.php?id=100078364057613'
            // },
        ]       
    },
    {
        image: 'daniel-okoev',
        name: 'Daniel Okoev',
        designation: 'Marketing Manager',
        location: 'Delaware, USA', 
        description2: 'Daniel Okoev is responsible',
        description3: 'for costumers accounting and tax filing needs.',
        socialNetwork: [
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/daniel-okoev-18144422b/'
            },
        ]       
    },
]

const TeamTwo = ({column , teamStyle}) => {
    return (
        <div className="row row--15 row--16">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-team ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>

{/* 
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
 */}
                                    
                                    <h5 className="description"><strong>{data.description ? data.description : <br/>}</strong></h5>
                                    <h5 className="description"><strong>{data.description1 ? data.description1 : <br/>}</strong></h5><br />
                                    <p className="description">{data.description2 ? data.description2 : <br/>}<br /> 
                                    {data.description3 ? data.description3 : <br/>}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`} target="_blank">{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TeamTwo;
