import React, {useEffect} from 'react';
import BlogClassicData from '../data/blog/BlogList.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import ReactGA from 'react-ga';

const BlogDetails = ({match: {params: {id}}}) => {
    const blogId = parseInt(id, 10)
    const data = BlogClassicData.filter(blog => blog.id === blogId);

    // Google Analytics.
    useEffect(() => {
        ReactGA.pageview(window.location.href.split("#")[1])
    }, [])

    const titleBlogSEO = "Blog " + id + " Details || LORD Intelligence, Inc.";

    return (
        <>
            <SEO title={titleBlogSEO} />
            <Layout>
                <div className="rn-blog-details-area">
                    <BlogDetailsContent data={data[0]}  />
                </div>
            </Layout>
        </>
    )
}
export default BlogDetails;