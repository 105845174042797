import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:+1 213 401 2773">+1 213 401 2773</a></p>
                                        <br/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:info@lord-intelligence.com">info@lord-intelligence.com</a></p>
                                        <br/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>651 N Broad St, Suite 206, 19709, <br />
                                           Middletown, Delaware</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15 row--16">
                <div className="col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-6 order-1 oder-lg-2">
                    <div className="thumbnail">
                        <img className="radius w-100" src="./images/about/contact-image.png" alt="Corporate" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactOne;